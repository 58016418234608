import { Flex, Box, Image } from "@chakra-ui/react";
import JapanPic from "./JapanTrip.png";
import SIPic from "./SI_Bunk3.png";
import HackathonPic from "./CodeChallenge.png";

const CardCarousel: React.FC<{ tabIndex: number }> = (props) => {
  const { tabIndex } = props;
  const images: string[] = [JapanPic, SIPic, HackathonPic];
  return (
    <Flex
      height={{ base: "30px", md: "60vh" }}
      width={{ base: "240px", md: "50vh" }}
      position="relative"
    >
      {images.map((image, id) => {
        const spacing = (): string => {
          return (2 - id) * 20 + "px";
        };
        const depth = (): number => {
          if (id === tabIndex) {
            return 3;
          }
          return id === 1 ? 2 : 2 - id;
        };
        return (
          <Box
            key={id.toString()}
            position="absolute"
            zIndex={depth()}
            bottom={spacing()}
          >
            <Image
              src={image}
              width="100%"
              height="100%"
              borderRadius="25px"
              border="4px solid #EEE"
              boxShadow={{ base: "lg", md: "2xl" }}
            />
          </Box>
        );
      })}
    </Flex>
  );
};

export default CardCarousel;
