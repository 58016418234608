import {
  Button,
  Flex,
  Link,
  Text,
  useColorMode,
  chakra,
  Box,
} from "@chakra-ui/react";
import SocialIcons from "../SocialMediaLogos/SocialIcons";

const Navbar: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Flex
      width="100%"
      py="12px"
      alignItems="center"
      justifyContent="space-around"
      flexDir={{ base: "column", md: "row" }}
      gap={{ base: "16px", md: "0" }}
      marginTop={{ base: "12px", md: "0" }}
    >
      <Flex gap="12px" alignItems="center">
        {/* Night Mode Btn */}
        <Button
          bgColor={colorMode === "light" ? "#27173A" : "#FFF"}
          height="24px"
          width="46px"
          borderRadius="40px"
          padding="0px 10px"
          boxShadow="0 3px 10px rgba(0, 0, 0, 0.1)"
          transition="all 0.3s ease-in-out"
          _hover={{}}
          onClick={toggleColorMode}
        >
          <Box
            id="colorCircle"
            height="19px"
            width="19px"
            borderRadius="100%"
            transform="auto"
            transition="all 0.3s ease-in-out"
            bgColor={colorMode === "light" ? "#ffd646" : "#1A202C"}
            translateX={colorMode === "light" ? "-11px" : "11px"}
            _hover={
              colorMode === "light"
                ? { translateX: "11px" }
                : { translateX: "-11px" }
            }
          />
        </Button>
        {/* Name Logo */}
        <Text fontFamily="nav.logo" fontSize="26px">
          <chakra.span color="#568BB3">Swee</chakra.span> Kah Ho
        </Text>
      </Flex>
      {/* Section Links */}
      <Flex fontSize="22px" gap="30px">
        <Link href="#about" _hover={{ textDecor: `none` }}>
          About
        </Link>
        <Link href="#portfolio" _hover={{ textDecor: `none` }}>
          Portfolio
        </Link>
        <Link href="#contact" _hover={{ textDecor: `none` }}>
          Contact
        </Link>
      </Flex>
      <SocialIcons />
    </Flex>
  );
};

export default Navbar;
