import { Flex, Image, Text, useColorMode } from "@chakra-ui/react";
import ProjectsVector from "./ProjectsVector.svg";
import ProjectCard from "./ProjectCard";
import ChalkImg from "./Chalk.png";
import MicroserviceImg from "./Microservice.png";
import ReversiImg from "./Reversi.png";

const ProjectsSection: React.FC = () => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      id="portfolio"
      marginTop={{ base: "calc(24vh + 180px)", md: "22vh" }}
      flexDir="column"
      alignItems="center"
      position="relative"
    >
      <Image
        position="absolute"
        height="200%"
        top={{ base: "-80vh", md: "-180px" }}
        right={{ base: "-60vw", md: "-520px" }}
        draggable="false"
        zIndex="0.5"
        src={ProjectsVector}
      />
      <Text
        paddingTop="20px"
        marginBottom="24px"
        fontSize={{ base: "4xl", md: "5xl" }}
        color={colorMode === "light" ? "#0F225E" : "#FFF"}
        zIndex="1"
      >
        FEATURED PROJECTS
      </Text>
      <Flex zIndex="1" gap="20px" flexDir={{ base: "column", md: "row" }}>
        <ProjectCard
          title="Chalk Webapp"
          url="https://chalk-pre-launch-landing-page.firebaseapp.com/"
          image={ChalkImg}
        >
          A platform that allows student to find academic and non-academic
          tutors of their choice
        </ProjectCard>
        <ProjectCard
          title="Student Ratings Microservice"
          url="https://github.com/kahkoii/Simulated-Ratings-Microservice"
          image={MicroserviceImg}
        >
          A fully containerized and easily deployable microservice that allows
          users to give each other ratings
        </ProjectCard>
        <ProjectCard
          title="Reversi CLI Game"
          url="https://github.com/kahkoii/Reversi-Game"
          image={ReversiImg}
        >
          Play Reversi on the command-line interface, and even face off against
          a bot with varying levels, all built in C++
        </ProjectCard>
      </Flex>
    </Flex>
  );
};

export default ProjectsSection;
