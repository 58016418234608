import { Flex, Text, Image, useColorMode } from "@chakra-ui/react";
import SocialIcons from "../SocialMediaLogos/SocialIcons";
import FooterVector from "./FooterVector.svg";
import ContactForm from "./ContactForm";

const Footer: React.FC = () => {
  const { colorMode } = useColorMode();
  return (
    <Flex
      id="contact"
      alignItems="center"
      justifyContent="space-around"
      padding="200px 0"
      overflowY="hidden"
    >
      <Image
        bottom="0px"
        position="absolute"
        justifySelf="end"
        alignSelf="end"
        draggable="false"
        zIndex="0.5"
        src={FooterVector}
      />
      <Flex
        flexDir={{ base: "column", md: "row" }}
        width="90vw"
        maxWidth="1000px"
        padding={{ base: "8vw", md: "80px" }}
        bgColor={colorMode === "light" ? "#FFF" : "#12131C"}
        boxShadow="2xl"
        borderRadius="30px"
        gap="60px"
        zIndex="1"
      >
        <Flex flexDir="column" gap="8px" width="100%">
          <Text
            fontSize="3xl"
            color={colorMode === "light" ? "#0F225E" : "#63B3ED"}
          >
            GET IN TOUCH
          </Text>
          <Text fontFamily="poppins" fontSize="md" marginBottom="20px">
            You may choose to reach me at my social media pages found below.
            Also, check out my GitHub projects too if you’d like. Or you may
            choose to leave me a message directly on the right!
          </Text>
          <SocialIcons vertical />
        </Flex>
        <Flex flexDir="column" width="100%">
          <Text
            fontSize="3xl"
            color={colorMode === "light" ? "#0F225E" : "#63B3ED"}
            marginBottom="8px"
          >
            CONTACT
          </Text>
          <ContactForm />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
