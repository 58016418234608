import { Flex, Image, Text, Link, useColorMode } from "@chakra-ui/react";

const ProjectCard: React.FC<{
  title: string;
  image: string;
  url: string;
  children: string;
}> = (props) => {
  const { title, image, url, children } = props;
  const { colorMode } = useColorMode();
  return (
    <Link
      href={url}
      target="_blank"
      _hover={{ fontStyle: "normal", transform: "translateY(-10px)" }}
    >
      <Flex
        flexDir="column"
        height="360px"
        width="300px"
        bgColor={colorMode === "light" ? "#FFF" : "#3B3B3B"}
        borderRadius="20px"
        boxShadow="xl"
      >
        <Image
          src={image}
          height="100%"
          width="auto"
          borderRadius="20px 20px 0 0"
          overflowX="hidden"
        />
        <Flex flexDir="column" padding="14px" fontFamily="poppins" gap="6px">
          <Text fontWeight="bold">{title}</Text>
          <Text fontSize="sm">{children}</Text>
        </Flex>
      </Flex>
    </Link>
  );
};

export default ProjectCard;
