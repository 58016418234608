import { Flex, useColorMode } from "@chakra-ui/react";
import Navbar from "../../components/Navbar/Navbar";
import HeroSection from "../../components/HeroSection/HeroSection";
import AboutSection from "../../components/AboutSection/AboutSection";
import ProjectsSection from "../../components/ProjectsSection/ProjectsSection";
import Footer from "../../components/Footer/Footer";
import "./GlobalStyles.css";

const Home: React.FC = () => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      flexDir="column"
      overflowX="hidden"
      bgColor={colorMode === "light" ? "#F8F8F8" : "#1A202C"}
    >
      <Navbar />
      <Flex flexDir="column" fontFamily="body">
        <HeroSection />
        <AboutSection />
        <ProjectsSection />
      </Flex>
      <Footer />
    </Flex>
  );
};

export default Home;
