import React from "react";
import ReactDOM from "react-dom/client";
import App from "./pages/App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

// Fonts
import "@fontsource/bayon";
import "@fontsource/fjalla-one";
import "@fontsource/poppins";
import "@fontsource-variable/open-sans";

const theme = extendTheme({
  initialColorMode: `light`,
  useSystemColorMode: false,
  fonts: {
    fjalla: `'Fjalla One', sans-serif`,
    bayon: `'Bayon', sans-serif`,
    poppins: `'Poppins', sans-serif;`,
    opensans: `'Open Sans Variable', sans-serif`,
    body: `'Bayon', sans-serif`,
    nav: {
      logo: `'Fjalla One', sans-serif`,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
);
