import {
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Textarea,
  Button,
  Text,
  Alert,
  AlertIcon,
  FormControl,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import emailjs from "emailjs-com";

type FormData = {
  email: string;
  title: string;
  message: string;
};

const ContactForm: React.FC = () => {
  const { register, handleSubmit } = useForm<FormData>();
  const [errorStatus, setErrorStatus] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    emailjs.send("gmail", "template_0qgxs0m", data, "97Hv_q5tq6IxlkLSF").then(
      (result) => {
        setFormSubmitted(true);
        console.log(result);
      },
      (error) => {
        setErrorStatus(true);
        console.log("Error: ", error.status);
      },
    );
  };

  return (
    <Flex id="messageForm" flexDir="column" gap="12px">
      <FormControl>
        <InputGroup>
          <InputLeftAddon children="Your Email" />
          <Input
            disabled={formSubmitted}
            fontFamily="opensans"
            borderRadius="30px"
            placeholder="steve@gmail.com"
            type="email"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register("email")}
          />
        </InputGroup>
      </FormControl>
      <FormControl>
        <InputGroup>
          <InputLeftAddon children="Message Title" />
          <Input
            disabled={formSubmitted}
            fontFamily="opensans"
            borderRadius="30px"
            placeholder="Hello World!"
            type="title"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...register("title")}
          />
        </InputGroup>
      </FormControl>
      <FormControl>
        <Textarea
          disabled={formSubmitted}
          fontFamily="opensans"
          borderRadius="20px"
          height="180px"
          placeholder="Your Message"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register("message")}
        />
      </FormControl>
      {formSubmitted ? (
        <Text
          fontSize="md"
          fontFamily="poppins"
          color="#0DE391"
          alignSelf={{ base: "center", md: "start" }}
        >
          Message Submitted Successfully
        </Text>
      ) : (
        <>
          {errorStatus && (
            <Alert status="error">
              <AlertIcon />
              THE MESSAGE FAILED TO SEND, PLEASE TRY AGAIN
            </Alert>
          )}
          <Button
            width={{ base: "100%", md: "150px" }}
            padding="20px"
            borderRadius="30px"
            color="#FFF"
            bgColor="#3C648C"
            _hover={{ bgColor: "#E9E9E9" }}
            alignSelf={{ base: "center", md: "start" }}
            onClick={handleSubmit(onSubmit)}
          >
            SEND
          </Button>
        </>
      )}
    </Flex>
  );
};

export default ContactForm;
