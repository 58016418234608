import { Flex, Link, Circle, Image, Icon, Text } from "@chakra-ui/react";
import { MdOutlineEmail } from "react-icons/md";
import GitHubIcon from "./GitHubIcon.svg";
import LinkedInIcon from "./LinkedInIcon.svg";

const SocialIcons: React.FC<{ vertical?: boolean }> = (props) => {
  const { vertical } = props;
  return (
    <Flex
      flexDir={vertical ? "column" : "row"}
      gap={{ base: "20px", md: "12px" }}
      fontFamily="opensans"
      fontWeight="semibold"
    >
      <Link href="https://github.com/kahkoii" isExternal>
        <Flex alignItems="center">
          <Circle
            size={{ base: "48px", md: "40px" }}
            padding="4px"
            bgColor="#FFF"
            border="1px solid #B1B1B1"
          >
            <Image src={GitHubIcon} height="100%" />
          </Circle>
          <Text display={vertical ? "inline" : "none"} marginLeft="10px">
            GITHUB @ KAHKOII
          </Text>
        </Flex>
      </Link>
      <Link href="https://www.linkedin.com/in/sweekahho/" isExternal>
        <Flex alignItems="center">
          <Circle
            size={{ base: "48px", md: "40px" }}
            padding="4px"
            bgColor="#FFF"
            border="1px solid #B1B1B1"
          >
            <Image src={LinkedInIcon} height="100%" />
          </Circle>
          <Text display={vertical ? "inline" : "none"} marginLeft="10px">
            LINKEDIN @ SWEE KAH HO
          </Text>
        </Flex>
      </Link>
      <Link href="mailto:sweekahho@gmail.com" isExternal>
        <Flex alignItems="center">
          <Circle
            color="black"
            size={{ base: "48px", md: "40px" }}
            padding="4px"
            bgColor="#FFF"
            border="1px solid #B1B1B1"
            fontSize={{ base: "4xl", md: "2xl" }}
          >
            <Icon as={MdOutlineEmail} height="100%" />
          </Circle>
          <Text display={vertical ? "inline" : "none"} marginLeft="10px">
            EMAIL @ sweekahho@gmail.com
          </Text>
        </Flex>
      </Link>
    </Flex>
  );
};

export default SocialIcons;
