import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home/Home";

const App: React.FC = () => (
  <Router>
    <Routes>
      {/* Public Pages */}
      <Route path="/" element={<Home />} />
      {/* Invalid Routes */}
      <Route path="/*" element={<Home />} />
    </Routes>
  </Router>
);

export default App;
