import {
  Flex,
  Box,
  Image,
  Text,
  Button,
  Link,
  useColorMode,
} from "@chakra-ui/react";
import ProfilePicVector from "./ProfilePicVector.svg";
import ProfilePic from "./ProfilePic.png";

const HeroSection: React.FC = () => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      flexDir={{ base: "column", md: "row" }}
      height={{ base: "55vh", md: "75vh" }}
      width="100%"
      alignItems="center"
      justifyContent="center"
      gap="10%"
    >
      <Flex
        flexDir="column"
        justifyContent="center"
        alignItems={{ base: "center", md: "start" }}
        textAlign={{ base: "center", md: "start" }}
        height={{ base: "90vw", md: "initial" }}
        width={{ base: "90vw", md: "25vw" }}
        order={{ base: "2", md: "1" }}
        position={{ base: "absolute", md: "relative" }}
        bgColor={{ base: "rgba(0,0,0,0.4)", md: "initial" }}
        borderRadius="100%"
      >
        <Text
          fontSize={{ base: "3xl", md: "4xl" }}
          color={{ base: "#6EAECF", md: "#568BB3" }}
        >
          HELLO
        </Text>
        <Text
          fontSize={{ base: "6xl", md: "7xl" }}
          color={
            colorMode === "light" ? { base: "#FFF", md: "#0F225E" } : "#FFF"
          }
          lineHeight="95%"
        >
          I'M KAH HO
        </Text>
        <Text
          fontSize={{ base: "lg", md: "2xl" }}
          width={{ base: "75%", md: "100%" }}
          color={{ base: "#6EAECF", md: "#568BB3" }}
          lineHeight="115%"
          paddingTop="10px"
        >
          A multilingual software developer based in Singapore
        </Text>
        <Button
          marginTop={{ base: "14px", md: "20px" }}
          borderRadius="30px"
          width={{ base: "120px", md: "150px" }}
          color="#000"
          bgColor="#81e6d9"
          _hover={{ bgColor: "#E9E9E9" }}
        >
          <Link href="#contact" _hover={{ textDecor: `none` }}>
            HIRE ME
          </Link>
        </Button>
      </Flex>
      <Box
        backgroundImage={ProfilePicVector}
        backgroundSize="auto 100%"
        backgroundRepeat="no-repeat"
        height={{ base: "90vw", md: "50vh" }}
        width={{ base: "90vw", md: "50vh" }}
        padding="6vh"
        order={{ base: "1", md: "2" }}
      >
        <Image src={ProfilePic} draggable="false" />
      </Box>
    </Flex>
  );
};

export default HeroSection;
